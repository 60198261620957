@use 'assets/styles/mixins' as m

.certFrame
    display: flex
    flex-direction: column
    width: calc(100% + 0.5px)
    height: 100%
    position: relative
    font-size: 0
    outline: none
    border: none
    padding: 0
    margin: 0
    img
        left: 0
        top: 0
        width: 100%
        height: auto
        display: block
        font-size: 0
.certFrameWrapper
    width: 330px
    position: absolute
    z-index: -1
.qrCodeWrapper
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    left: 0
    right: 0
    bottom: 39px
    top: auto
    margin: auto
    width: fit-content
    font-size: 0
.qrCodeContainer
    position: relative
    width: 100%
    height: 100%
.qrCode
    aspect-ratio: 1
    font-size: 0
    position: absolute
    left: 0
    right: 0
    margin: auto
    bottom: 0
    top: 0
    display: flex
    justify-content: center
    align-items: center
.qrCodeBg
    aspect-ratio: 1
    font-size: 0
.genInfo
    @include m.fontInter(16px, var(--text-color), 500, 120%)
.wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 30px
    width: 330px
    margin: 0 auto
    padding: 100px 0 0 0

.cert
    @include m.fontInter(26px, var(--text-color), 500, 120%)
    width: 100%
.certInfo
    display: flex
    flex-direction: column
    row-gap: 10px
    width: 100%
    &__item
        @include m.fontInter(16px, var(--text-color), 500, 120%)

.preloader
    width: 100%
    height: 100dvh
    display: flex
    justify-content: center
    align-items: center
    @include m.s900
        margin: 30px 0

@media print
    @page
        margin: 0cm
        padding: 0
        size: A4
    body
        padding: 0
        box-sizing: border-box
        position: relative
    header
        display: none

    button
        display: none
        span
            display: none

    #Header, #Footer, .cert, .qrCode, .certInfo
        display: none !important

    *, *::before, *::after
        color: #000

    .certFrame
        width: 100% !important
        position: absolute
        left: 0
        top: 0
        img
            display: block

    .wrapper
        width: 100% !important

    .qrCodePrint
        display: block !important
        bottom: 38px
        background-color: white
        padding: 6px
        box-sizing: border-box
        border-radius: 5px