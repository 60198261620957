body, html
    margin: 0
    padding: 0
a
    text-decoration: none

button
    border: none
    padding: 0
    margin: 0
    outline: none
    background: unset
    cursor: pointer

h1, h2
    margin: 0
    padding: 0