@use 'assets/styles/mixins' as m

.page
    width: 100%
    height: 100%
    min-height: 100dvh
    position: relative
    z-index: 1

.pageBackGround
    position: fixed
    top: 0
    left: 0
    height: 100dvh
    width: 50%
    z-index: 0
    background-color: var(--text-color)
    display: flex
    justify-content: center
    align-items: center
    @include m.s900
        display: none

.form
    display: flex
    flex-direction: column
    width: 350px
    height: fit-content
    row-gap: 30px
    @include m.s900
        width: 100%
    
.title
    @include m.fontInter(20px, var(--text-color), 500, 120%)
.formContainer
    width: 50%
    position: absolute
    right: 0
    top: 0
    bottom: 0
    left: auto
    margin: auto
    display: flex
    justify-content: center
    align-items: center
    @include m.s900
        width: calc(100% - 40px)
        left: 0
.authBtn
    width: 100%