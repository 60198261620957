@use 'assets/styles/mixins' as m

.accessDenied
    width: fit-content
    @include m.fontInter(20px, var(--text-color), 500, 120%)
    margin: 0 auto
    padding: 80px 0 0 0
    display: flex
    flex-direction: column
    row-gap: 30px
    width: 350px
    @include m.s900
        width: 100%
