@use 'assets/styles/mixins' as m

.controls
    display: flex
    align-items: center
    column-gap: 25px
    margin: 0 0 0 auto
    @include m.s900
        column-gap: 20px

.item
    font-size: 0
    &:empty
        display: none

.onMobile
    display: none
    @include m.s900
        display: block