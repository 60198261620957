.container
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    height: 100dvh
    
.loader
    width: 30px
    height: 30px
    border: 3px solid black
    border-bottom-color: transparent
    border-radius: 50%
    display: inline-block
    box-sizing: border-box
    animation: rotation .7s linear infinite

@keyframes rotation
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)