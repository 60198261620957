.container
    position: sticky
    top: 200px
    z-index: 10
    width: 100%
    display: flex
    justify-content: center

.loader
    width: 30px
    height: 30px
    border: 3px solid var(--text-color)
    border-bottom-color: transparent
    border-radius: 50%
    display: inline-block
    box-sizing: border-box
    animation: rotation .7s linear infinite

@keyframes rotation
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)