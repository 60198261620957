@use 'assets/styles/mixins' as m

.fio
    position: absolute
    left: 0
    right: 0
    bottom: 520px
    top: auto
    margin: auto
    width: fit-content
    @include m.fontInter(26px, #FFFFFF, 500, 120%)
    z-index: 1000
    opacity: 1

.light
    color: white !important
    -webkit-print-color-adjust: exact
.dark
    color: black !important
    -webkit-print-color-adjust: exact