@use 'assets/styles/mixins' as m

.gallery
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(var(--rgb-gallery-bg-color), .6)
    z-index: 10000
    display: flex
    justify-content: center
    align-items: center

.content
    padding: 100px
    box-sizing: border-box
    font-size: 0
    height: 100%
    max-width: 100%
    @include m.s900
        padding: 35px
.image
    display: none
    height: 100%
    width: 100%
    object-fit: contain

.showImage
    display: block

.toggleBtn
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    padding: 0
    display: flex
    justify-content: center
    align-items: center
    padding: 15px
    transition: .3S
    font-size: 0
    @include m.s900
        padding: 0
    svg
        width: 35px
        height: 35px
        path
            fill: var(--body-color)
    &:hover
        transition: .3S
        background-color: rgba(var(--rgb-body-color), .1)

.toggleBtn_left
    left: 0
    svg
        transform: rotate(90deg)

.toggleBtn_right
    right: 0
    svg
        transform: rotate(-90deg)
    
.closeBtn
    font-size: 0
    position: absolute
    right: 20px
    top: 20px
    z-index: 2
    svg
        width: 30px
        height: 30px
        path
            fill: var(--body-color)