@use 'assets/styles/mixins' as m

.header
    display: flex
    align-items: center

.logo
    height: 30px
    width: auto
    margin: 0 40px 0 35px
    @include m.s900
        height: 24px
        margin: 0 0 0 15px
    svg
        height: 100%
        path
            fill: var(--text-color)

.menu
    width: 24px
    height: 24px
    cursor: pointer
    font-size: 0
    position: relative
    svg
        height: 100%
        width: 100%
        padding: 10px
        position: absolute
        left: -10px
        top: -10px
        path
            fill: var(--text-color)