.page
    width: 100%
    height: 100%
    min-height: 100dvh
    position: relative
    z-index: 1
    display: flex
    flex-direction: column

.pageBackGround
    position: fixed
    top: 0
    left: 0
    background-image: url('/../public/images/decorative-bg.png')
    background-position: top center
    background-size: cover
    height: 100dvh
    width: 100%
    z-index: 0