@use 'assets/styles/mixins' as m

.policy
    display: flex
    flex-direction: column
    width: 50%
    @include m.s900
        width: 100%
h1.mainTitle
    @include m.fontInter(26px, var(--theme-color), 500, 120%)
    margin: 0 0 40px 0
h2.secondTitle
    @include m.fontInter(22px, var(--text-color), 500, 120%)
    margin: 30px 0 20px 0
.block
    @include m.fontInter(14px, var(--text-color), 400, 140%)
    margin: 0 0 20px 0