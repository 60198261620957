@use 'assets/styles/mixins' as m

.btn
    position: fixed
    z-index: 5
    right: 50px
    bottom: 50px
    width: 45px
    height: 45px
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    background-color: var(--theme-color)
    -webkit-box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.6)
    -moz-box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.6)
    box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.6)
    transition: .2s
    &:hover
        -webkit-box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.2)
        -moz-box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.2)
        box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.2)
        transition: .2s
    @include m.s900
        right: 20px
        bottom: 20px