@use 'assets/styles/mixins' as m

.btn
    border: none
    outline: none
    padding: 0
    width: 100%
    height: 50px
    box-sizing: border-box
    border-radius: 5px
    background-color: var(--theme-color)
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &[disabled]
        opacity: .3
    span
        @include m.fontInter(16px, white, 500, 140% )