@use 'assets/styles/mixins' as m
    
.content
    position: fixed
    width: 300px
    left: 50px
    bottom: 50px
    z-index: 1000
    display: flex
    flex-direction: column
    row-gap: 15px
    @include m.s900
        width: calc(100% - 40px)
        top: auto
        left: 0
        right: 0
        bottom: 20px
        margin: auto

.item
    background-color: var(--microalert-bg-color)
    @include m.fontInter(14px, var(--microalert-text-color), 500, 120%)
    border-radius: 5px
    padding: 15px
    position: relative
    &::before
        position: absolute
        content: ''
        width: 100%
        height: 3px
        left: 0
        bottom: -5px
        border-radius: 5px
        background-color: var(--theme-color)
        animation: alerttime 3s linear

@keyframes alerttime
    0%
        width: 100%
    100%
        width: 0%