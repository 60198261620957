@use 'assets/styles/mixins' as m

.footer
    width: 100%
    display: flex
    align-items: center
    padding: 20px 0
    box-sizing: border-box
    border-top: 1px solid rgba(var(--rgb-text-color), .3)
    justify-content: space-between
    @include m.s900
        flex-direction: column
        align-items: flex-start
        row-gap: 10px
.textItem
    @include m.fontInter(14px, var(--text-color), 500, 120%)