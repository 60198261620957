@use 'assets/styles/mixins' as m
    
.container
    display: flex
    flex-direction: column
    height: inherit

.status
    @include m.fontInter(16px, var(--text-color), 500, 120%)

.info
    @include m.fontInter(14px, var(--text-color), 500, 120%)
    margin: 20px 0 0 0
    &:empty
        display: none

.content
    display: flex
    flex-direction: column
    width: 100%

.btns
    display: flex
    flex-direction: column
    margin: auto 0 0 0
    row-gap: 15px

.preloader
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    @include m.s900
        margin: 30px 0