@use 'assets/styles/functions' as f

$main-colors: ("body-color" : #EDEEF0, "text-color" : #151515, "border-color" : #151515, "main-icon-color" : #FFFFFF, "input-bg-color" : #FFFFFF, "disabled-input-bg-color" : #cccccc, "theme-color" : #472AE8, "button-bg-color" : #FFFFFF, "theme-inner-color" : #FFFFFF, "green-color" : #00DE4A, "red-color" : #f44f74, "red-text-color" : #f44f74, "microalert-bg-color" : #151515, "microalert-text-color" : #FFFFFF, "modal-bg-color" : #151515, "loader-gradient-color" : #FFFFFF, "gallery-bg-color" : #000000, "menu-text-color" : #FFFFFF)
$dark-colors: ("body-color" : #27262B, "text-color" : #DEDEDE, "border-color" : #DEDEDE, "main-icon-color" : #DEDEDE, "input-bg-color" : #2D2C31, "disabled-input-bg-color" : #383838, "theme-color" : #3290FF, "button-bg-color" : #2D2C31, "theme-inner-color" : #DEDEDE, "green-color" : #50FF8A, "red-color" : #f44f74, "red-text-color" : #f44f74, "microalert-bg-color" : #FFFFFF, "microalert-text-color" : #151515, "modal-bg-color" : #151515, "loader-gradient-color" : #FFFFFF, "gallery-bg-color" : #000000, "menu-text-color" : #FFFFFF)
$brand-colors: ("body-color" : #111111, "text-color" : #FFFFFF, "border-color" : #FFFFFF, "main-icon-color" : #FFFFFF, "input-bg-color" : #1B1B1B, "disabled-input-bg-color" : #383838, "theme-color" : #B58B26, "button-bg-color" : #1B1B1B, "theme-inner-color" : #FFFFFF, "green-color" : #50FF8A, "red-color" : #d1274d, "red-text-color" : #f97c7c, "microalert-bg-color" : #FFFFFF, "microalert-text-color" : #151515, "modal-bg-color" : #151515, "loader-gradient-color" : #FFFFFF, "gallery-bg-color" : #000000, "menu-text-color" : #FFFFFF)

.mainTheme
    @each $key, $value in $main-colors
        --#{$key} : #{$value}
        --rgb-#{$key} : #{f.HexToRGB($value)}

.darkTheme
    @each $key, $value in $dark-colors
        --#{$key} : #{$value}
        --rgb-#{$key} : #{f.HexToRGB($value)}
    
.brandTheme
    @each $key, $value in $brand-colors
        --#{$key} : #{$value}
        --rgb-#{$key} : #{f.HexToRGB($value)}

.app
    width: 100%
    min-height: 100vh
    background-color: var(--body-color)