@use 'assets/styles/mixins' as m
    
.label
    @include m.inputLabel
    select
        cursor: pointer
        background-color:  var(--input-bg-color)
        border: 1px solid rgba(var(--rgb-border-color), .5)
        border-radius: 5px
        box-sizing: border-box
        @include m.fontInter(16px, var(--text-color), 400, 140%)
        height: 45px
        outline: none
        padding: 0 15px
        appearance: none
            -moz-appearance: none
                -webkit-appearance:  none
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzQ1XzE0MCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KPHJlY3QgeD0iMjQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMjQgMCkiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzQ1XzE0MCkiPgo8cGF0aCBkPSJNMTIgMTMuNTk5OUwxNS45IDkuNjk5OTNDMTYuMDgzMyA5LjUxNjU5IDE2LjMxNjYgOS40MjQ5MyAxNi42IDkuNDI0OTNDMTYuODgzMyA5LjQyNDkzIDE3LjExNjYgOS41MTY1OSAxNy4zIDkuNjk5OTNDMTcuNDgzMyA5Ljg4MzI2IDE3LjU3NSAxMC4xMTY2IDE3LjU3NSAxMC4zOTk5QzE3LjU3NSAxMC42ODMzIDE3LjQ4MzMgMTAuOTE2NiAxNy4zIDExLjA5OTlMMTIuNyAxNS42OTk5QzEyLjYgMTUuNzk5OSAxMi40OTE2IDE1Ljg3MDggMTIuMzc1IDE1LjkxMjRDMTIuMjU4MyAxNS45NTQxIDEyLjEzMzMgMTUuOTc0OSAxMiAxNS45NzQ5QzExLjg2NjYgMTUuOTc0OSAxMS43NDE2IDE1Ljk1NDEgMTEuNjI1IDE1LjkxMjRDMTEuNTA4MyAxNS44NzA4IDExLjQgMTUuNzk5OSAxMS4zIDE1LjY5OTlMNi42OTk5NSAxMS4wOTk5QzYuNTE2NjIgMTAuOTE2NiA2LjQyNDk1IDEwLjY4MzMgNi40MjQ5NSAxMC4zOTk5QzYuNDI0OTUgMTAuMTE2NiA2LjUxNjYyIDkuODgzMjYgNi42OTk5NSA5LjY5OTkzQzYuODgzMjggOS41MTY1OSA3LjExNjYyIDkuNDI0OTMgNy4zOTk5NSA5LjQyNDkzQzcuNjgzMjggOS40MjQ5MyA3LjkxNjYyIDkuNTE2NTkgOC4wOTk5NSA5LjY5OTkzTDEyIDEzLjU5OTlaIiBmaWxsPSIjMTUxNTE1Ii8+CjwvZz4KPC9zdmc+Cg==")
        background-repeat: no-repeat
        background-position: right 10px center
        &:focus-visible
            border-color: var(--border-color)
        option
            color: var(--text-color)
        &[disabled]
            background-color: var(--disabled-input-bg-color)
            border-color: var(--disabled-input-bg-color)
            opacity: 1
            color: rgba(var(--rgb-text-color), .4)
    input[type="text"]
        @include m.basicInput
    input[type="text"]::placeholder
        @include m.inputPlaceholder
        
.title
    @include m.inputTitle
    
.fieldError
    @include m.fieldError