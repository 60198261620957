@use 'assets/styles/mixins' as m
    
.list
    display: flex
    flex-wrap: wrap
    width: calc(100% + 10px)
    left: -5px
    position: relative
    margin: 0
    &::before
        transition: .3s

.empty
    width: 100%
    display: flex
    justify-content: center
    margin: 25px 0 0 0
    @include m.fontInter(16px, var(--text-color), 500, 120%)

.preloader
    width: 100%
    margin: 55px 0 0 0
    display: flex
    justify-content: center
    
.fetching
    &::before
        transition: .3s
        position: absolute
        content: ''
        width: 100%
        height: 100%
        background-color: rgba(var(--rgb-body-color), 0.6)
        z-index: 10