@use 'assets/styles/mixins' as m

.message
    width: 100%
    margin: 0
    padding: 15px
    box-sizing: border-box
    border-radius: 5px
    background-color: rgba(var(--rgb-text-color), 1)
    @include m.fontInter(14px, var(--text-color), 500, 140% )
    &_error
        background-color: rgba(var(--rgb-red-color), 1)
    &_success
        background-color: rgba(var(--rgb-green-color), 1)