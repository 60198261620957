@use 'assets/styles/mixins' as m
.list
    display: flex
    flex-direction: column
    row-gap: 20px
    margin: 40px 0 0 0
.item
    display: flex
    flex-direction: column
    row-gap: 5px
    span
        @include m.fontInter(16px, var(--text-color), 500, 120%)
        &:first-child
            font-size: 13px
            opacity: .5

.certInfo
    margin: 20px 0 0 0
    display: flex
    flex-direction: column
    row-gap: 20px
    span
        @include m.fontInter(16px, var(--text-color), 500, 140%)
    ul
        padding: 0 0 0 18px
        margin: 0
        li
            list-style-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cmVjdCB3aWR0aD0iOSIgaGVpZ2h0PSI5IiByeD0iMiIgZmlsbD0iI0I1OEIyNiIvPgo8L3N2Zz4K")
            span
                @include m.fontInter(16px, var(--text-color), 500, 140%)

.container
    width: 350px
    margin: 0 auto
    @include m.s900
        width: 100%
h1.title
    @include m.fontInter(26px, var(--theme-color), 500, 120%)
    margin: 0 0 20px 0
    display: flex
    align-items: center
    column-gap: 10px
    svg
        width: 24px
        height: 24px
        path
            fill: var(--theme-color)