@use 'assets/styles/mixins' as m

.notice
    position: relative

.icon
    font-size: 0
    animation: alert 1s linear infinite
    cursor: pointer
    svg
        path
            fill: var(--theme-color)
        
.advice
    position: absolute
    @include m.fontInter(13px, var(--theme-inner-color), 500, 120%)
    background-color: var(--theme-color)
    width: 250px
    right: 34px
    top: -5px
    padding: 10px
    box-sizing: border-box
    border-radius: 5px
    display: none
    opacity: 0
    transition: .2s
    box-shadow: 0px 11px 11px -6px rgba(var(--rgb-theme-color), 0.6)
    &_opened
        display: block
        opacity: 1
    @include m.s900
        width: calc(100vw - 40px)
        position: fixed
        left: 0
        right: 0
        margin: auto
        top: 55px

.btn
    margin: 10px 0 0 0

@keyframes alert
    0%
        opacity: 0
    20%
        opacity: 1
    80%
        opacity: 1
    100%
        opacity: 0