@font-face
    font-family: "Inter"
    src: local("Inter"),
    src: url("./../fonts/Inter-Regular.ttf") format('truetype')
    font-weight: 400

@font-face
    font-family: "Inter"
    src: local("Inter"),
    src: url("./../fonts/Inter-Medium.ttf") format('truetype')
    font-weight: 500

@mixin fontInter($size: false, $color: false, $weight: false, $lh: false)
    font-family: 'Inter', sans-serif
    @if $size
        font-size: $size
    @if $color 
        color: $color 
    @if $weight
        font-weight: $weight
    @if $lh
        line-height: $lh

@mixin s900
    @media screen and (max-width: 900px)
        @content

@mixin basicInput
    background-color: var(--input-bg-color)
    border: 1px solid rgba(var(--rgb-border-color), .5)
    border-radius: 5px
    box-sizing: border-box
    @include fontInter(16px, var(--text-color), 500, 140%)
    height: 45px
    outline: none
    padding: 0 15px
    width: 100%
    position: relative
    &:focus-visible
        border-color: var(--border-color)
    &[disabled]
        background-color: var(--disabled-input-bg-color)
        border-color: var(--disabled-input-bg-color)

@mixin inputLabel
    display: flex
    flex-direction: column
    position: relative
    row-gap: 2px
    width: 100%

@mixin inputTitle
    position: relative
    @include fontInter(13px, var(--text-color), 500, 120%)
    height: fit-content
    opacity: .5
    pointer-events: none
    right: auto

@mixin inputPlaceholder
    @include fontInter(16px, var(--text-color), 500, 140%)
    opacity: .4
    
@mixin fieldError
    @include fontInter(13px, var(--red-text-color), 500, 120%)
    position: absolute
    bottom: -19px


    