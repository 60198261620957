@use 'assets/styles/mixins' as m

.container
    width: 350px
    margin: 0 auto
    @include m.s900
        width: 100%

.presetField
    display: flex
    flex-direction: column
    row-gap: 5px
    div.title
        @include m.fontInter(13px, var(--text-color), 500, 120%)
        opacity: .5
    div.field
        @include m.fontInter(26px, var(--text-color), 500, 120%)

.photos
    display: flex
    flex-direction: column
    width: 100%
    row-gap: 20px

.photosList
    display: flex
    flex-wrap: wrap
    width: calc(100% + 10px)

.photo
    width: 25%
    padding: 0 10px 10px 0
    box-sizing: border-box
    &[disabled]
        pointer-events: none
        .photoContainer
            border-color: var(--disabled-input-bg-color)
            background-color: var(--disabled-input-bg-color)
            svg, img
                opacity: .4
    @include m.s900
        width: 50%

.photoContainer
    width: 100%
    position: relative
    padding-top: 100%
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid var(--border-color)
    box-sizing: border-box
    border-radius: 5px
    background-color: var(--button-bg-color)
    cursor: pointer
    &:has(img)
        border: none
        cursor: inherit
    img
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 5px
    input[type="file"]
        display: none
    svg
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        margin: auto
        path
            fill: var(--main-icon-color)

.delPhotoBtn
    cursor: pointer
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: 0
    margin: auto
    width: 28px
    height: 28px
    background-color: red
    border-radius: 5px
    background-color: var(--button-bg-color)
    svg
        
        path
            fill: var(--main-icon-color)

h1.title
    @include m.fontInter(26px, var(--theme-color), 500, 120%)
    margin: 0 0 20px 0
.undertitle
    @include m.fontInter(14px, var(--text-color), 500, 140%)
    margin: 0 0 30px 0

.photoFieldLabel
    @include m.inputLabel
.photoFieldTitle
    @include m.inputTitle
.photoFieldUndertitle
    @include m.fontInter(13px, var(--text-color), 500, 140%)

.fieldError
    @include m.fieldError
    position: relative
    top: 0
    bottom: 0
    margin: 5px 0 0 0