@use 'assets/styles/mixins' as m

.modal
    position: fixed
    left: 0
    bottom: 0
    right: 0
    overflow: hidden
    height: 100dvh
    // height: calc(var(--vh, 1vh) * 100)
    background-color: rgba(var(--rgb-modal-bg-color), .5)
    display: flex
    justify-content: flex-end
    z-index: 100
    padding: 20px
    box-sizing: border-box
    @include m.s900
        overflow-y: auto
        padding: 90px 20px 20px 20px
        bottom: auto
        top: 0

.container
    padding: 30px
    box-sizing: border-box
    background: var(--body-color)
    border-radius: 10px
    width: 40%
    display: flex
    flex-direction: column
    overflow: hidden
    @include m.s900
        width: 100%
        padding: 20px
        height: fit-content
.header
    display: flex
    justify-content: space-between
    align-items: flex-start
    column-gap: 10px
    margin: 0 0 10px 0
    &Title
        @include m.fontInter(32px, var(--text-color), 500, 120%)
        @include m.s900
            font-size: 26px
    &Close
        font-size: 0
        position: relative
        top: 7px
        cursor: pointer
        padding: 0
        background: unset
        border: none

.content
    height: 100%
    overflow-y: auto
    /* FF */
    scrollbar-width: thin
    scrollbar-color: rgba(var(--rgb-text-color), .2) rgba(var(--rgb-text-color), .03)
    @include m.s900
        overflow-y: unset

    /* Chrome/Edge/Safari */
    &::-webkit-scrollbar
        height: 10px
        width: 10px
    &::-webkit-scrollbar-track
        background: rgba(var(--rgb-text-color), .2)
    &::-webkit-scrollbar-thumb
        background-color: rgba(var(--rgb-text-color), .03)
        border-radius: 5px
        border: 3px solid rgba(var(--rgb-text-color), .2)