@use 'assets/styles/mixins' as m
    
.item
    width: 20%
    padding: 5px
    box-sizing: border-box
    @include m.s900
        width: 100%
    
.container
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    box-sizing: border-box
    padding: 10px
    border: 1px solid rgba(var(--rgb-border-color), .17)
    background-color: var(--button-bg-color)
    border-radius: 10px
    cursor: pointer
    transition: .2s
    &:hover
        border-color: var(--border-color)
        transition: .2s

.printBtn
    position: absolute
    top: 6px
    right: 6px
    padding: 4px
    font-size: 0
    opacity: .7
    transition: .2s
    &:hover
        opacity: 1
        transition: .2s
    svg
        path
            fill: var(--text-color)

.date
    @include m.fontInter(13px, var(--text-color), 500, 120%)
    opacity: .4
    margin: 0 0 15px 0
.code
    @include m.fontInter(16px, var(--text-color), 500, 120%)
    margin: 0 0 5px 0
.status
    @include m.fontInter(13px, var(--text-color), 500, 120%)
    &_new
        color: var(--theme-color)
    &_active
        color: var(--text-color)
        opacity: .4
    &_registered
        color: var(--green-color)