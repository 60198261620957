@use 'assets/styles/mixins' as m

.menu
    display: flex
    flex-direction: column
    row-gap: 20px
    @include m.s900
        margin: 50px 0 0 0
        align-items: center

.item
    position: relative
    @include m.fontInter(26px, var(--menu-text-color), 500, 140%)
    display: flex
    align-items: center
    @include m.s900
        font-size: 20px
    &Active::before
        position: relative
        content: ''
        border-radius: 2px
        width: 4px
        height: 23px
        background-color: var(--menu-text-color)
        margin: 0 6px 0 0
        