@use 'assets/styles/mixins' as m
    
.label
    @include m.inputLabel
    input[type="date"]
        @include m.basicInput
        @include m.s900
            opacity: 0
    input[type="date"]::placeholder
        @include m.inputPlaceholder
        
.title
    @include m.inputTitle
    
.fieldError
    @include m.fieldError

.date
    position: absolute
    width: 24px
    height: 43px
    right: 15px
    top: auto
    bottom: 1px
    left: auto
    background-color: var(--input-bg-color)
    margin: auto
    display: flex
    align-items: center
    pointer-events: none
    cursor: pointer
    &[disabled]
        background-color: var(--disabled-input-bg-color)
        svg
            opacity: .4
    svg
        path
            fill: var(--text-color)

.dateCloneField
    @include m.basicInput
    position: absolute
    bottom: 0
    left: 0
    display: none
    pointer-events: none
    &[disabled]
        color: rgba(var(--rgb-text-color), .4)
    @include m.s900
        display: flex
        align-items: center
