
.page
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100dvh
    display: flex
    flex-direction: column
    justify-content: center
.icon
    position: absolute
    font-size: 0
    svg
        width: 70px
        height: 70px
        path
            fill: var(--text-color)
.container
    width: 130px
    aspect-ratio: 1
    display: flex
    justify-content: center
    align-items: center
    margin: 0 auto
.preloader
    width: 100%
    height: 100%
    border-radius: 0
    border-radius: 50%
    margin: auto
    animation: rotate 1.3s linear infinite
    &::before
        content: ""
        box-sizing: border-box
        position: absolute
        inset: 0px
        border-radius: 50%
        border: 4px solid var(--text-color)
        animation: prixClipFix 1.3s linear infinite
        // transition: .4s

.found
    .preloader
        animation-iteration-count: 0.5
        animation-fill-mode: forwards
        &::before
            // opacity: 0
            border-color: var(--green-color)
            transition: 1s
            animation-iteration-count: 0.5
            animation-fill-mode: forwards
    .icon
        svg path
            fill: var(--green-color)
            transition: 1s

.notFound
    .preloader
        animation-iteration-count: 0.5
        animation-fill-mode: forwards
        &::before
            // opacity: 0
            border-color: var(--red-color)
            transition: 1s
            animation-iteration-count: 0.5
            animation-fill-mode: forwards
    .icon
        svg path
            fill: var(--red-color)
            transition: 1s


@keyframes rotate
    100%
        transform: rotate(360deg)
    

@keyframes prixClipFix
    0%
        clip-path: polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)
    12%
        clip-path: polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)
    25%
        clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)
    37%
        clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)
    50%
        clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)
    62%
        clip-path: polygon(50% 50%,100% 0,100% 0,100% 100%,0 100%,0 0)
    75%
        clip-path: polygon(50% 50%,100% 100%,100% 100%,100% 100%,0 100%,0 0)
    87%
        clip-path: polygon(50% 50%,0 100%,0 100%,0 100%,0 100%,0 0)
    100%
        clip-path: polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)