@use 'assets/styles/mixins' as m
    
.wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 330px
    margin: 0 auto
    padding: 50px 0
.qrcodeWrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 30px
    width: 330px
    margin: 0 auto
    padding: 50px 0 0 0
.qrcode
    width: 330px
    height: 330px
    background-color: #fff
    display: flex
    justify-content: center
    align-items: center
    border-radius: 10px
    position: relative
.qrCodeBorder
    position: absolute
    z-index: 100
    left: 0
    top: 0
    width: 100%
    height: 100%
.genInfo
    @include m.fontInter(16px, var(--text-color), 500, 120%)
.code
    @include m.fontInter(26px, var(--text-color), 500, 120%)

.codeInfo
    display: flex
    flex-direction: column
    row-gap: 30px
    span
        @include m.fontInter(16px, var(--text-color), 500, 140%)

@media print
    @page
        margin: 0cm
        padding: 0
        size: A4
    body
        padding: 0
        box-sizing: border-box
    header
        display: none

    button
        display: none
        span
            display: none

    #Header, #Footer
        display: none !important

    *, *::before, *::after
        color: #000

    .container
        width: 100% !important
    
    .codeInfo
        display: flex