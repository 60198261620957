@use 'assets/styles/mixins' as m

.undertitle
    @include m.fontInter(14px, var(--text-color), 500, 140%)
    margin: 0 0 30px 0

.fieldContent
    display: flex
    flex-direction: column
    row-gap: 20px
    margin: 0 0 30px 0

.presetField
    display: flex
    flex-direction: column
    row-gap: 5px
    div.title
        @include m.fontInter(13px, var(--text-color), 500, 120%)
        opacity: .5
    div.field
        @include m.fontInter(16px, var(--text-color), 500, 120%)
    
.btns
    display: flex
    flex-direction: column
    row-gap: 15px