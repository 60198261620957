@use 'assets/styles/mixins' as m
    
.btn
    border: none
    outline: none
    padding: 0
    width: 100%
    height: 50px
    border: 1px solid var(--border-color)
    box-sizing: border-box
    border-radius: 5px
    background-color: unset
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    &[disabled]
        opacity: .3
    span
        @include m.fontInter(16px, var(--text-color), 500, 140%)
    img
        margin: 0 10px
        width: 24px
        height: 24px