@use 'assets/styles/mixins' as m
    
.label
    @include m.inputLabel
    input[type="text"]
        @include m.basicInput
    input[type="text"]::placeholder
        @include m.inputPlaceholder
        
.title
    @include m.inputTitle
    
.fieldError
    @include m.fieldError