@use 'assets/styles/mixins' as m

.scrollable
    width: 100%
    height: 100%
.list
    display: flex
    flex-direction: column
    row-gap: 20px
    margin: 40px 0 0 0
.item
    display: flex
    flex-direction: column
    row-gap: 5px
    span
        @include m.fontInter(16px, var(--text-color), 500, 120%)
        &:first-child
            font-size: 13px
            opacity: .5

.images
    display: flex
    flex-wrap: wrap
    margin: 30px 0 0 0