@use 'assets/styles/mixins' as m

.modal
    position: fixed
    left: 0
    bottom: 0
    right: 0
    height: 100dvh
    background-color: rgba(var(--rgb-modal-bg-color), .9)
    display: flex
    justify-content: flex-start
    z-index: 100
    box-sizing: border-box
    @include m.s900
        overflow-y: auto
        bottom: auto
        top: 0

.container
    padding: 30px
    box-sizing: border-box
    // background: var(--body-color)
    width: fit-content
    height: fit-content
    display: flex
    flex-direction: column
    @include m.s900
        width: 100%
        padding: 20px
        // height: 100dvh
.close
    font-size: 0
    position: absolute
    top: 30px
    right: 30px
    cursor: pointer
    padding: 0
    background: unset
    border: none
    @include m.s900
        right: 10px
        top: 10px
    svg path
        fill: var(--body-color)

.content
    height: 100%
    overflow-y: auto
    /* FF */
    scrollbar-width: thin
    scrollbar-color: rgba(var(--rgb-text-color), .2) rgba(var(--rgb-text-color), .03)
    @include m.s900
        overflow-y: unset

    /* Chrome/Edge/Safari */
    &::-webkit-scrollbar
        height: 10px
        width: 10px
    &::-webkit-scrollbar-track
        background: rgba(var(--rgb-text-color), .2)
    &::-webkit-scrollbar-thumb
        background-color: rgba(var(--rgb-text-color), .03)
        border-radius: 5px
        border: 3px solid rgba(var(--rgb-text-color), .2)