@use 'assets/styles/mixins' as m
    
.btn
    outline: none
    width: fit-content
    height: fit-content
    background-color: unset
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    box-sizing: border-box
    &[disabled]
        opacity: .3
    &:hover
        .container
            border-color: var(--border-color)
            transition: .2s
    &_active
        .container
            border-color: var(--theme-color)
            background-color: var(--theme-color)
            span
                color: var(--theme-inner-color)

.container
    padding: 7px 10px
    box-sizing: border-box
    width: fit-content
    height: fit-content
    border: 1px solid rgba(var(--rgb-text-color), .5)
    background-color: var(--button-bg-color)
    border-radius: 5px
    display: flex
    justify-content: center
    align-items: center
    transition: .2s
    span
        @include m.fontInter(13px, var(--text-color), 500, 120%)